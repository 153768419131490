<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
      class="pl-0 pr-0 pt-0"
      style="max-width: 600px;"
    >
      <!-- <v-row>
        <v-col cols="12">
          <div class="d-flex flex-end">
            <v-btn
              class="br-btn mr-3"
              @click="submit"
              :loading="loading"
            >Atualizar</v-btn>

            
          </div>
        </v-col>
      </v-row> -->

      <v-row>
        <v-col cols="12" class="pt-0">
          <v-card 
            :color="variables.colorPrimaryGecom"
            elevation="0"
            rounded="0"
            class="box-saldo"
            dark
          >
            <v-card-title
              class="box-saldo-titulo"
            >
              <span>
                {{ user.name }}
              </span>

              <v-btn
                icon
                @click="switchData()"
                class="box-saldo-titulo-icon"  :loading="loading"
              >
                <v-icon>
                  {{ getSwitch }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text
              class="box-saldo__content"
            >
              <span>SALDO</span>
              <span class="ocultar-dados" v-if="olcultarDados">.....</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
                v-else
              >
                <strong>{{ data.deve_atual | currency }}</strong>
              </v-skeleton-loader>
              
            </v-card-text>
          </v-card>
        </v-col> 

        <v-col cols="6">
          <v-card 
            :color="variables.colorPrimaryGecom"
            elevation="0"
            rounded="0"
            class="box-saldo"
            dark
          >
            <v-card-text
              class="box-saldo__content"
            > 
              <span class="ocultar-dados" v-if="olcultarDados">.....</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
                v-else
              >
                <strong>{{ data.caixa | currency }}</strong>
              </v-skeleton-loader>
              <span>À receber</span>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card 
            :color="variables.colorPrimaryGecom"
            elevation="0"
            rounded="0"
            class="box-saldo"
            dark
          >
            <v-card-text
              class="box-saldo__content"
            >
              <span class="ocultar-dados" v-if="olcultarDados">.....</span>
              <v-skeleton-loader
                class="mx-auto d-flex justify-center"
                max-width="300"
                type="heading"
                :loading="loading"
                v-else
              >
                <strong>{{ data.pagto | currency }}</strong>
              </v-skeleton-loader>
              <span>À pagar</span>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-btn 
            :color="variables.colorPrimary"
            elevation="4"
            class="box-saldo pt-6 pb-6"
            :width="'100%'"
            :to="{ name: 'gefin-mobile' }"
            dark
          >
            GEFIN
          </v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn 
            :color="variables.colorPrimary"
            elevation="4"
            class="box-saldo pt-6 pb-6"
            :width="'100%'"
            :to="{ name: 'gecom-mobile' }"
            dark
          >
            GECOM
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-btn
            :to="{name: 'premios'}"
            :color="variables.colorSecondary"
            dark
            width="100%"
            elevation="4"
            class="pt-6 pb-6"
          >
          <v-icon
            right
            dark
            class="mr-1"
          >
            mdi-file-search
          </v-icon>
          Validar prêmio
        </v-btn>
        </v-col>

        <!-- <v-col cols="12">
          <v-card
            rounded="0"
            elevation="1"
            :color="variables.colorPrimaryGecom"
          >
            <v-card-text
              class="box-saldo__content pt-1 pb-1"
            >
              Mais Informações
            </v-card-text>
          </v-card>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
// import Events from '@/core/service/events'
// import { parseTime, errorSnackbar } from '@/core/service/utils'

/**
 * MIXIN
 */
// import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'relatorioGecomHome'

export default {
  name: 'Cadastro',
  components: {
    // autoCompleteEntities: () => import('../../components/autoCompleteEntities/gecom'),

  },
  data: () => ({
    loading: false,
    switch: false,
    timeRequest: 60,
    lastTimeRequest: null,
    olcultarDados: true
  }),

  watch: {

  },

  mounted () {
    // this.getData()
    //   .finally(() => this.loading = false)

  },

  computed: {
    ...mapGetters(namespaceStore, ['data', 'configGecom', 'totais']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('credenciador', ['item']),
    variables: () => variables,
    getSwitch() {
      return this.switch ? 'mdi-eye' : 'mdi-eye-off'
    }
  },

  methods: {
    ...mapActions(namespaceStore, ['getData', 'clearItens']),
    // ...mapActions('credenciador', {
    //   getPromotor: 'getItens'
    // }),
    switchData () {
      this.switch = !this.switch

      console.log((moment().unix() - this.lastTimeRequest) < this.timeRequest)

      const blockTimeRequest = this.lastTimeRequest ? ((moment().unix() - this.lastTimeRequest) < this.timeRequest) : false
      if (blockTimeRequest) { 
        this.olcultarDados = !this.switch
        return 
      }

      if (this.switch) {
        this.olcultarDados = false
        this.loading = true
        this.getData()
            .finally(() => {
              this.loading = false
              this.lastTimeRequest  = moment().unix()
            })
      } else {
        this.olcultarDados = true
      }
    }
  },

  beforeDestroy () {}
}
</script>

<style lang="scss" scoped>
  .box-saldo {
    color: #FFF;
    &-titulo {
      text-align: center;
      font-size: .9rem;
      width: 100%;
      display: flex;
      padding-top: .4rem;
      justify-content: center;

      &-icon {
        margin-left: auto;
        cursor: pointer;
      }
    }

    &__content {
      text-align: center;
     span {
       color: #Fff;
       display: block;
     }

     strong {
       color: #Fff;
       font-size: 1.6rem;
     }
    }
  }

  .ocultar-dados {
    font-size: 4.6rem;
    /* line-height: 0; */
    display: inline-block !important;
    line-height: 0;
  }
</style>>
